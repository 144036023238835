import MyDoctorTemplate from '@/modules/questionnaire/components/steps/common/my-doctor/my-doctor/MyDoctorTemplate';

export default {
  title: 'Steps/MyDoctor/MyDoctorTemplate',
  component: MyDoctorTemplate
};

const createStory = props => () => ({
  components: { MyDoctorTemplate },
  storyProps: props,
  wrapperStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template:
    '<div :style="$options.wrapperStyles"><my-doctor-template v-bind="$options.storyProps" /></div>'
});

const DOCTORS_CLINICS = [
  { clinicName: 'My new very cool clinic1' },
  { clinicName: 'My new very cool clinic2' },
  { clinicName: 'My new very cool clinic3' },
  { clinicName: 'My new very cool clinic4' }
];

export const NotDoctorPatient = createStory({
  alreadyPatient: 'no',
  wantToConnectWithClinicVisible: true,
  doctorFullName: 'FirstName LastName',
  wantToConnectWithClinic: 'My new very cool clinic1',
  clinicLocations: DOCTORS_CLINICS
});

export const AlreadyDoctorPatient = createStory({
  alreadyPatient: 'yes',
  isAssociatedWithClinicVisible: true,
  isAssociatedWithClinic: 'My new very cool clinic3',
  doctorFullName: 'FirstName LastName',
  clinicLocations: DOCTORS_CLINICS
});
